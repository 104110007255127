<template>
	<div class="sc-card-product explode style2 mg-bt bd-collectioncard" :class="number_per_row+'-per-line'">
		<div class="card-media">
			<router-link :to="'/collection-page/'+collection.symbol">
        <div class="bd-collectioncard-img">
          <img v-show="load_picture" :src="route_bo + '/storage/' + collection.nft_preview" @load="load_picture = true">
          <img v-show="!load_picture" src="/assets/images/bluediamonds/loader-light.gif" class="bd-light" />
          <img v-show="!load_picture" src="/assets/images/bluediamonds/loader-dark.gif" class="bd-dark" />
        </div>
        <div class="bd-collectioncard-img-round">
          <img v-show="load_picture_round" :src="route_bo + '/storage/' + collection.logo" @load="load_picture_round = true">
          <img v-show="!load_picture_round" src="/assets/images/bluediamonds/loader-light.gif" class="bd-light" />
          <img v-show="!load_picture_round" src="/assets/images/bluediamonds/loader-dark.gif" class="bd-dark" />
        </div>
			</router-link>
			
		</div>
		<div class="card-title" style="justify-content: center;font-size: 18px;">
			<h5 style="display: block;">
				<router-link :to="'/collection-page/'+collection.symbol">
					<span>{{ collection.name }}</span>
				</router-link>
			</h5>
		</div>
		<br/>
		<div class="meta-info bd-collectioncard-meta-1">
			<div class="info"><span>Volume: {{collection.volume | format_number}}</span></div>
			<div class="info"><span>Floor: ◎ {{collection.floor_price}}</span></div>
		</div>
		<div class="meta-info bd-collectioncard-meta-2">
			<div class="info"><span>Mint date: {{collection.mint_date}}</span></div>
			<div class="info"><span>Mint price: ◎ {{collection.mint_price}}</span></div>
		</div>
	</div>
</template>

<script>

import axios from 'axios';

let config_axios = {
  headers: {
    'Content-Type': 'application/json;',
  }
}

export default {
  name: 'CollectionCard',
  components: {

  },
  props: {
    collection: {},
    context: {
      type: String,
      default: '',
    },
    number_per_row: {
      type: String,
      default: 'three',
    },
  },
  data:function(){
    return {
      route_bo :process.env.VUE_APP_ROOT_BO,
      load_picture: false,
      load_picture_round: false,
    }
  },
  created: function() {


  },
  methods:{

  },
}
</script>

