<template>
	<div>
		<!-- Discover -->
		<section class="flat-title-page inner bd-container-padding bd-homepage-container-intro">
			<div class="themesflat-container">
				<div class="row bd-homepage-row-intro">
					<div class="col-md-1"></div>
					<div class="col-md-5 bd-homepage-intro">
						<h1 class="bd-homepage-title">Buying and selling NFTs<br/> made <span class="color-primary">easy</span>, <span class="color-primary">enjoyable</span>, and <span class="color-primary">profitable</span></h1>
						
						<p class="bd-homepage-baseline">
						User-centric and reward-based Solana Marketplace
						</p>
						
						<router-link to="/all-collections" class="badge bd-tag bd-homepage-tag active bd-homepage-tag-hover">Explore Collections</router-link>
						<router-link to="/all-nfts" class="badge bd-tag bd-homepage-tag active bd-homepage-tag-hover">Explore NFTs</router-link>
					</div>
					<div class="col-md-1"></div>
					<div class="col-md-4 bd-homepage-intro-nft">
						<img src="/assets/images/bluediamonds/home/banner/1.png" class="bd-homepage-nft bd-homepage-nft-1" />
						<img src="/assets/images/bluediamonds/home/banner/2.png" class="bd-homepage-nft bd-homepage-nft-2" />
						<img src="/assets/images/bluediamonds/home/banner/3.png" class="bd-homepage-nft bd-homepage-nft-3" />
						<img src="/assets/images/bluediamonds/home/banner/4.png" class="bd-homepage-nft bd-homepage-nft-4" />
						<img src="/assets/images/bluediamonds/home/banner/5.png" class="bd-homepage-nft bd-homepage-nft-5" />
					</div>
				</div>
			</div>
		</section>
		
		<!-- Our exclusive collections
		<section class="bd-homepage-exclusive-collections">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-md-8 bd-homepage-exclusive-collections-nfts">
					</div>
					<div class="col-md-4 bd-homepage-exclusive-collections-text">
						<h1 class="bd-homepage-title">Our exclusive<br>collections</h1>
						
						<p class="bd-homepage-baseline">Digital marketplace for crypto collectibles
						Buy, Sell, and discover exclusive digital assets.</p>
						
						<router-link to="/all-collections" class="badge bd-tag bd-homepage-tag active" style="float: right;">See more</router-link>
						
						
					</div>
				</div>
			</div>
		</section>
		-->
		<section class="bd-homepage-token-info bd-homepage-slides">
			<span class="fas fa-angle-left bd-homepage-token-info-previous-card bd-homepage-token-info-arrow" @click="change_card(-1)"></span>
			<span class="fas fa-angle-right bd-homepage-token-info-next-card bd-homepage-token-info-arrow" @click="change_card(1)"></span>
			<div class="themesflat-container bd-homepage-slide-container" :class="[slide.id_card, slide.active]" v-for="(slide, key) in slides" :key="key" :style="'background: url(https://blue-diamonds.easydev.run/storage/'+slide.picture+'); padding-left:0px; padding-right: 0px;'">
				<div class="bd-homepage-slide-container-inside">
					<span></span>
					<span></span>
					<span></span>
					<h2>{{slide.title}}</h2>
					<p>{{slide.description}}</p>
					
					<router-link :to="'/'+slide.link" v-show="slide.link_style==36"><span class="badge bd-tag active">{{slide.link_anchor}}</span></router-link>
					<div><span class="badge bd-tag" v-show="slide.link_style==37" style="width: 200px; cursor: auto;">{{slide.link_anchor}}</span></div>
					<span></span>
					<span></span>
					<span></span>
					
				</div>
			</div>
			
		</section>
		
		<section class="bd-homepage-token-info">
			<div class="themesflat-container bd-homepage-token-info-container">
				<div class="row bd-homepage-token-info-row">
					<div class="col-md-1"></div>
					<div class="col-md-3" style="display: flex;align-items: center;">
						<div class="bd-homepage-token-img"><img src="/assets/images/bluediamonds/icon_token_bd.gif" /></div>
					</div>
					<div class="col-md-2" style="min-width: 10%"></div>
					<div class="col-md-5 bd-homepage-token-text">
						<h2 style="color: gold;">20% of our revenue</h2>
						<h2>go to our users via<br>our native $BD token</h2>
						<span class="bd-homepage-token-explanation">For every ◎ worth of NFTs that you buy or sell on BlueDiamonds, we airdrop you 20 $BD tokens that provide you with many exciting perks!</span>
						<router-link to="/faq"><span class="bd-homepage-token-learnmore" >Learn more</span></router-link>
					</div>
				</div>
			</div>
		</section>
		
		<!-- Popular collections -->
		<section class="bd-homepage-popular-collections bd-container-padding">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-md-12">
						<h1 class="bd-homepage-title bd-homepage-title-featured-collections">Featured collections</h1>
					</div>
					<div class="col-md-12">
						<div class="box-epxlore" id="collection-box">
							<CollectionCard :collection="collection" :number_per_row="'four'" v-for="(collection, key) in collections" :key="key"></CollectionCard>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<!-- Blue band
		<section class="bd-homepage-blueband background-primary">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-md-3">
						<i class="fa fa-wallet"></i>
						
						<h2>Connect your wallet</h2>
						
						<p>Connect the SPL wallet of your choice by clicking the wallet icon in the top right corner</p>
					</div>
					<div class="col-md-1"></div>
					<div class="col-md-3">
						<i class="fa fa-wallet"></i>
						
						<h2>Connect your wallet</h2>
						
						<p>Connect the SPL wallet of your choice by clicking the wallet icon in the top right corner</p>
					</div>
					<div class="col-md-1"></div>
					<div class="col-md-3">
						<i class="fa fa-wallet"></i>
						
						<h2>Connect your wallet</h2>
						
						<p>Connect the SPL wallet of your choice by clicking the wallet icon in the top right corner</p>
					</div>
					
				</div>
			</div>
		</section>
		-->

		<!-- Browse by collections -->
		<section class="bd-homepage-brows-by-collection bd-container-padding">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-md-12 center">
						<h1 class="bd-homepage-title bd-homepage-title-browse">Browse by collections</h1>
					</div>
					
					
					<div class="col-md-4">
						<router-link to="/all-collections/8">
							<div class="bd-homepage-browse-box">
								<div>
									<p>2D</p>
									<img src="/assets/images/bluediamonds/home/2D/1.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-1" />
									<img src="/assets/images/bluediamonds/home/2D/2.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-2" />
									<img src="/assets/images/bluediamonds/home/2D/3.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-3" />
									<img src="/assets/images/bluediamonds/home/2D/4.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-4" />
									<img src="/assets/images/bluediamonds/home/2D/5.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-5" />
								</div>
							</div>
						</router-link>
					</div>
					
					<div class="col-md-4">
						<router-link to="/all-collections/9">
							<div class="bd-homepage-browse-box">
								<div>
									<p>3D</p>
									<img src="/assets/images/bluediamonds/home/3D/1.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-1" />
									<img src="/assets/images/bluediamonds/home/3D/2.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-2" />
									<img src="/assets/images/bluediamonds/home/3D/3.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-3" />
									<img src="/assets/images/bluediamonds/home/3D/4.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-4" />
									<img src="/assets/images/bluediamonds/home/3D/5.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-5" />
								</div>
							</div>
						</router-link>
					</div>
					
					<div class="col-md-4">
						<router-link to="/all-collections/10">
							<div class="bd-homepage-browse-box">
								<div>
									<p>Pixel</p>
									<img src="/assets/images/bluediamonds/home/pixel/1.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-1" />
									<img src="/assets/images/bluediamonds/home/pixel/2.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-2" />
									<img src="/assets/images/bluediamonds/home/pixel/3.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-3" />
									<img src="/assets/images/bluediamonds/home/pixel/4.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-4" />
									<img src="/assets/images/bluediamonds/home/pixel/5.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-5" />
								</div>
							</div>
						</router-link>
					</div>
					
					<div class="col-md-4">
						<router-link to="/all-collections/6">
							<div class="bd-homepage-browse-box">
								<div>
									<p>Gaming</p>
									<img src="/assets/images/bluediamonds/home/gaming/1.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-1" />
									<img src="/assets/images/bluediamonds/home/gaming/2.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-2" />
									<img src="/assets/images/bluediamonds/home/gaming/3.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-3" />
									<img src="/assets/images/bluediamonds/home/gaming/4.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-4" />
									<img src="/assets/images/bluediamonds/home/gaming/5.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-5" />
								</div>
							</div>
						</router-link>
					</div>
					
					<div class="col-md-4">
						<router-link to="/all-collections/4">
							<div class="bd-homepage-browse-box">
								<div>
									<p>Reward</p>
									<img src="/assets/images/bluediamonds/home/reward/1.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-1" />
									<img src="/assets/images/bluediamonds/home/reward/2.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-2" />
									<img src="/assets/images/bluediamonds/home/reward/3.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-3" />
									<img src="/assets/images/bluediamonds/home/reward/4.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-4" />
									<img src="/assets/images/bluediamonds/home/reward/5.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-5" />
								</div>
							</div>
						</router-link>
					</div>
					
					<div class="col-md-4">
						<router-link to="/all-collections/7">
							<div class="bd-homepage-browse-box">
								<div>
									<p>Metaverse</p>
									<img src="/assets/images/bluediamonds/home/metaverse/1.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-1" />
									<img src="/assets/images/bluediamonds/home/metaverse/2.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-2" />
									<img src="/assets/images/bluediamonds/home/metaverse/3.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-3" />
									<img src="/assets/images/bluediamonds/home/metaverse/4.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-4" />
									<img src="/assets/images/bluediamonds/home/metaverse/5.png" class="bd-homepage-nft-browse-collection bd-homepage-nft-browse-collection-5" />
								</div>
							</div>
						</router-link>
					</div>
					
					
					
					<div class="col-md-12 center">
						<router-link to="/all-collections" class="badge bd-tag bd-homepage-tag active  bd-homepage-tag-hover">Explore collections</router-link>
					</div>
				</div>
			</div>
		</section>

		<!-- Explore nft -->
		<section class="bd-homepage-browse-nfts bd-container-padding">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-md-12 center">
						<h1 class="bd-homepage-title bd-homepage-title-browse">Explore NFTs</h1>
					</div>
					<div class="box-epxlore" id="nfts-box">
						<NftCard :nft="nft" :nft_per_line="'four'" v-for="(nft, key) in nfts" :key="key"></NftCard>
					</div>
					
					<div class="col-md-12 center">
						<router-link to="/all-nfts" class="badge bd-tag bd-homepage-tag active  bd-homepage-tag-hover">Explore NFTs</router-link>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import CollectionCard from '@/components/CollectionCard.vue';
import NftCard from '@/components/NftCard.vue';
import $ from 'jquery'

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

var timeout_change_card = false;

export default {
	name: 'Home',
	components: {
		CollectionCard,
		NftCard
	},
	data: function () {

		return {
	
			collections: {},
			slides: {},
			nfts: {},
      card : 1,
		}
	},
	props: {

	},
	created: function() {
		
		var $this = this;
		
		axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/reload_list/home', {}, config_axios).then(function(elements) {

			$this.collections = elements.data.collections;
		
		});
		
		axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/home/nfts', config_axios).then(function(elements) {

			$this.nfts = elements.data.nfts;
		
		});

	},
  methods : {

    change_card : function (nb){
	
		clearTimeout(timeout_change_card);

      var $this = this;
      $this.card += nb

      if($this.card > $('.bd-homepage-slide-container').length) {
        $this.card = 1
      }
      if($this.card < 1)
        $this.card = $('.bd-homepage-slide-container').length;

      if(nb == 1) {
        $('.bd-homepage-slide-container').css('z-index','')
        $('.previous').css('z-index','-1')
        $('.previous').removeClass('previous')
        $('.active-slide').addClass('previous')
        $('.active-slide').removeClass('active-slide')
        $('.card_' + $this.card).addClass('active-slide')
      }
      else{
        $('.bd-homepage-slide-container').css('z-index','')
        $(".bd-homepage-slide-container").not('.previous').not('.active-slide').css('z-index','-1');
        $('.previous').addClass('active-slide')
        $('.previous').removeClass('previous')
        $('.active-slide').removeClass('active-slide')
        $('.card_' + $this.card).addClass('active-slide')
        if($this.card == 1)
          $('.card_' + $('.bd-homepage-slide-container').length).addClass('previous')
        else
          $('.card_' + ($this.card - 1)).addClass('previous')
      }
		timeout_change_card = setTimeout(function () {

			$this.change_card(1)

		},4000);

    },

  },
  mounted () {

    var $this = this;
	
	axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/home/slides', config_axios).then(function(elements) {

		$this.slides = elements.data.slides;
	
	});

    timeout_change_card = setTimeout(function () {

          $this.change_card(1)

    },4000);

  }
}
</script>

